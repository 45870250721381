import React from 'react';
import Footer from "../../core/layout/footer/footer";
import {motion} from 'framer-motion';
import './teamStyles.css';

let s3BaseURL = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL;

function TeamView(props) {

    const {isMDView, isXSView, isMobileView, isSMView} = props;

    const renderHeader = () => {
        return (
            <div className="pt-0 cover-background top-space-margin sm-pb-0"
                 style={{
                     backgroundImage: `url(${s3BaseURL + '/Images/team/teamBG.jpg'})`
                 }}>
                <div className="page-container">
                    <div className="row align-items-end h-200px sm-h-100px ">
                        <div className="col-lg-9 col-md-6 position-relative text-left page-title-extra-large d-flex justify-content-left align-item-end">
                            <div className="justify-content-left header-img-text slideUpSlowly"> Team</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    const renderDescription = () => {
        return (
            <div className='d-flex flex-wrap gap-5'>
                <div className='d-flex card-containerflex-leadership'
                style={{
                   maxWidth : 440
                }}>
                    <div className=''>
                        <div className="border-0 rounded shadow-sm overflow-hidden ms-0">
                            <div className="image">
                                <a href="#">
                                    <img src={s3BaseURL + '/Images/team/aniket.jpg'}
                                         alt="Aniket Ausekar"
                                         className="img-fluid"/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="border card-containerflex-leadership rounded shadow-sm overflow-hidden shadow-lg border-0">
                    <div className="bg-white">
                        <div className="p-3 px-4">
                            <div className="d-flex align-items-center mb-2 ps-2">
                                <div
                                    className="d-flex align-items-center list-icons justify-content-center">
                                    <i className="fa-solid fa-circle"/>
                                </div>
                                <div className="card-heading ms-2">Aniket Ausekar</div>
                            </div>
                            <div className="mb-3">
                                <div className="text-dark theme-font-content-xs">
                                    As a Founder and CEO, Aniket oversees a variety of Technology and Strategy roles
                                    at Evolvus. Evolvus, has built significant data assets and has proven expertise,
                                    global operations in the area of Preclinical and Translational data driven drug
                                    discovery, since 2001, this entire business has been bootstrapped by Aniket over
                                    the last 23 years. Aniket is an alumni from the University of Pennsylvania,
                                    Philadelphia. At Penn his graduate work spanned multi-disciplinary areas at the
                                    intersection of Medicine and Biotechnology including gene regulation,
                                    transcriptomics and computational genomics. In 1999 he started his professional
                                    career at Wall Street in New York. His expertise and background spans the fields
                                    of Data Science, Molecular Biology, Text Mining, Bioinformatics as well as
                                    Computational Drug Design.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="border-0 card-containerflex-leadership rounded shadow-lg overflow-hidden card-container">
                    <div className="bg-white">
                        <div className="p-2 pb-5">
                            <div
                                className="theme-color-black theme-font-section-title theme-font-low text-center px-4"
                                style={{lineHeight: '38px'}}>
                                Biology is far from understanding exactly how a single cell
                                develops into a baby, but research suggests that human
                                development can ultimately be explained in terms of
                                biochemistry and molecular biology. Most scientists would
                                make a similar statement about evolution.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const renderLeadershipUI = () => {
        return (
            <div className="bg-white d-flex flex-column pb-0 page-container section-divider justify-content-md-center justify-content-lg-start" id="up-section">
                <div className="text-dark section-title pb-5 mt-5 text-center text-md-start"
                     style={{animationDuration: '0.9s', animationDelay: '0ms'}}>
                    Leadership
                </div>
                {renderDescription()}
            </div>
        );
    };

    const renderMobileAdvisoryBoard = () => {
        return (
            <div className="d-flex flex-wrap gap-5 grid justify-content-xl-center px-0">
                <div className=" mb-4 card-containerflex shadow-lg card border-0 rounded-3">
                    <div className="card-img w-100">
                        <img src={s3BaseURL + '/Images/team/andreas.jpg'} className="card-img-top w-100"
                             alt="Andreas Bender"/>
                        <div className="card-body px-4">
                            <div className="d-flex align-items-center mb-2 ps-2">
                                <div className="d-flex align-items-center list-icons justify-content-center">
                                    <i className="fa-solid fa-circle"/>
                                </div>
                                <div className="card-heading ms-2 text-gray">Andreas Bender (PhD)</div>
                            </div>
                            <div className=" theme-font-content-xs">
                                Dr Andreas Bender is from 2025 onwards Professor for Machine Learning in Medicine at
                                Khalifa University, Abu Dhabi, leading a research group analyzing diverse types of
                                medical, chemical, and biological data to support drug discovery and personalized
                                medicine. Before that, he has been Professor for Molecular Informatics at Cambridge
                                University, and Director for Digital Life Sciences at Nuvisan in Berlin, as well as an
                                Associate Director for Data Science and AI in the Clinical Pharmacology & Safety
                                Sciences group at AstraZeneca. On the entrepreneurial side, Andreas was involved in
                                setting up Healx Ltd. and PharmEnable Ltd., both based in Cambridge/UK, as well as
                                Pangea Bio in London/UK and Berlin/Germany. He received his PhD from the University of
                                Cambridge and worked in the Lead Discovery Informatics group at Novartis in Cambridge/MA
                                as well as at Leiden University in the Netherlands before his current post.

                            </div>
                        </div>
                    </div>
                </div>
                <div className="mb-4 card-containerflex shadow-lg card border-0 rounded-3">
                    <div>
                        <img src={s3BaseURL + '/Images/team/timHoctor.jpg'}
                             className="card-img-top"
                             alt="Tim Hoctor"/>
                        <div className="card-body px-4">
                            <div className="d-flex align-items-center mb-2 ps-2">
                                <div
                                    className="d-flex align-items-center list-icons justify-content-center">
                                    <i className="fa-solid fa-circle"/>
                                </div>
                                <div className="card-heading ms-2 text-gray">Tim Hoctor</div>
                            </div>
                            <div className="card-text theme-font-content-xs">
                                Tim Hoctor has been in the global life science community throughout his
                                career. Starting in the mid-90’s at Oracle, Tim joined Molecular Design
                                (MDL) in 1997 and subsequently Elsevier, as they acquired MDL, in a
                                variety of technical, product strategy roles and senior leadership
                                roles, including Vice President of Global Life Science Services. Tim has
                                been a strategic advisor to inoCells, a cell therapy startup in women’s
                                health, and has been a member of the Board of Directors and advisory
                                board member of the Pistoia Alliance and an active contributor to SABPA
                                (the Sino American BioPharma Association). Tim also has extensive
                                experience mentoring emerging founders and entrepreneurs in the life
                                science community in the US and Europe.
                            </div>
                        </div>
                    </div>
                </div>
                <div className=" mb-4 card-containerflex shadow-lg card border-0 rounded-3">
                    <div>
                        <img src={s3BaseURL + '/Images/team/jonnyWray.jpg'} className="card-img-top"
                             alt="Jonny Wray"/>
                        <div className="card-body px-4">
                            <div className="d-flex align-items-center mb-2 ps-2">
                                <div
                                    className="d-flex align-items-center list-icons justify-content-center">
                                    <i className="fa-solid fa-circle"/>
                                </div>
                                <div className="card-heading ms-2 text-gray">Jonny Wray
                                    (PhD)
                                </div>
                            </div>
                            <div className="card-text theme-font-content-xs">
                                Jonny Wray (PhD) is the Chief Technology Officer (CTO) at Nucleome
                                Therapeutics and is responsible for the development of their proprietary
                                platform. The platform integrates cutting-edge computational and
                                experimental approaches aiming to decode the human regulatory genome and
                                enable the elucidation of disease mechanisms, identification of
                                mechanistically defined patient subpopulations, and the development of
                                first-in-class medicines targeting those mechanisms. Prior to joining
                                Nucleome, Jonny served as the CTO of e-therapeutics plc, where he was
                                responsible for conceptualising and building their computational drug
                                discovery platform. Previously he held leadership roles in
                                bioinformatics and software engineering at Five Prime Therapeutics and
                                BD Clontech, both in the San Francisco Bay Area. He was awarded a PhD in
                                Computational Neuroscience from the University of Newcastle and
                                conducted post-doctoral work as a Fellow in Theoretical Neuroscience at
                                The Neurosciences Institute in New York and San Diego.
                            </div>
                        </div>
                    </div>
                </div>
                <div className=" mb-4 card-containerflex shadow-lg card border-0 rounded-3">
                    <div>
                        <img src={s3BaseURL + '/Images/team/yogesh.jpg'} className="card-img-top"
                             alt="Yogesh Kulkarni"/>
                        <div className="card-body px-4 ">
                            <div className="d-flex align-items-center mb-2 ps-2">
                                <div
                                    className="d-flex align-items-center list-icons justify-content-center">
                                    <i className="fa-solid fa-circle"/>
                                </div>
                                <div className="card-heading ms-2 text-gray">Yogesh Kulkarni</div>
                            </div>
                            <div className="card-text theme-font-content-xs">
                                Yogesh Kulkarni serves as the Vice President of Product and Solution
                                Strategy for Generative AI Frontline Solutions at Zebra Technologies. In
                                this role, he leads a cross-functional team focused on harnessing
                                advancements in Generative AI to tackle key customer challenges. At
                                Zebra Technologies, Yogesh has played a pivotal role in identifying
                                vertical market opportunities across industries, crafting cross-business
                                unit strategies, and advising leadership on growth initiatives. Before
                                joining Zebra Technologies, Yogesh was the Co-Chief Executive Officer at
                                Antuit.ai, where he spearheaded the development of AI solutions that
                                empowered clients to make smarter decisions and automate processes.
                                Before Antuit.ai, Yogesh held various Product Management and Leadership
                                positions at Prognos, SAS Institute, and Octon Technologies. Yogesh
                                holds a Bachelor of Engineering in Electronics from Shivaji University.
                            </div>
                        </div>
                    </div>
                </div>
                <div className=" mb-4 card-containerflex shadow-lg card border-0 rounded-3">
                    <div>
                        <img src={s3BaseURL + '/Images/team/vijay.jpg'} className="card-img-top"
                             alt="Vijay Deshmukh"/>
                        <div className="card-body px-4 ">
                            <div className="d-flex align-items-center mb-2 ps-2">
                                <div
                                    className="d-flex align-items-center list-icons justify-content-center">
                                    <i className="fa-solid fa-circle"/>
                                </div>
                                <div className="card-heading ms-2 text-gray">Vijay Deshmukh</div>
                            </div>
                            <div className="card-text theme-font-content-xs">
                                Vijay has held roles of increasing responsibilities in multiple diverse
                                Fortune 500 companies namely Trane Technologies, General Electric, and
                                Cummins. He currently leads a $4B portfolio at Trane Technologies in a
                                Chief Product Officer capacity. He has led regional and global
                                portfolios that include industrial equipment and services, smart
                                systems, and digital offerings. He loves the strategy landscape that
                                capitalizes on internal core competencies and external ecosystem for
                                growth. He is passionate about delivering robust growth with
                                customer-led go-to-market strategies, environmental sustainability, and
                                building organizational excellence. With a well-rounded experience in
                                multiple disciplines, he brings a general management mindset and clarity
                                to align organizations to capitalize on market opportunities through
                                efficient investment strategies. Vijay holds an M.B.A. from Purdue
                                University and an M.S. in Mechanical Engineering from Colorado State
                                University.
                            </div>
                        </div>
                    </div>
                </div>
                <div className=" mb-4 card-containerflex shadow-lg card border-0 rounded-3">
                    <div>
                        <img src={s3BaseURL + '/Images/team/adarsh.jpg'} className="card-img-top"
                             alt="Adarsh Nigam"/>
                        <div className="card-body px-4 ">
                            <div className="d-flex align-items-center mb-2 ps-2">
                                <div
                                    className="d-flex align-items-center list-icons justify-content-center">
                                    <i className="fa-solid fa-circle"/>
                                </div>
                                <div className="card-heading ms-2 text-gray">Adarsh Nigam</div>
                            </div>
                            <div className="card-text theme-font-content-xs">
                                Adarsh Nigam is a Partner with India and Singapore offices at Stanton
                                Chase, a renowned global Executive Search firm; he also spearheads
                                Higher Education Executive Search initiatives across the APAC region.
                                Adarsh has extensive experience across diverse industries, including
                                pharmaceuticals, e-commerce, food, fitness, and wellness. He excels in
                                business development, strategy, and corporate relations. Beyond his
                                corporate engagements, Adarsh is recognized for his keen ability to
                                forge connections across various domains and verticals. As an avid
                                investor in health-tech, genomics, and knowledge management startups, he
                                actively contributes to the growth and development of emerging ventures.
                                Currently pursuing a Ph.D. in management from Symbiosis International
                                University, Pune, Adarsh remains dedicated to academic and professional
                                excellence. Outside of his professional endeavors, he indulges his
                                passions for travel, baking, and Hindi cinema.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const renderAdvisoryBoardUI = () => {
        return (
            <div className=" position-relative z-index-1 pb-0" id="up-section"
                 style={{
                     backgroundColor: 'var(--gray-light)'
                 }}>
                <div className="page-container">
                    <motion.div initial={{animationPlayState: 'paused'}}
                                whileInView={{animationPlayState: 'running'}}
                                viewport={{once: true, amount: 0.1}}
                                className="slideUpSlowly d-flex justify-content-center justify-content-md-start">
                        <div className="">
                            <div className="text-dark-gray section-title pt-5 pb-5 w-100" >
                                Advisory Board
                            </div>
                        </div>
                    </motion.div>
                    <motion.div initial={{animationPlayState: 'paused'}}
                                whileInView={{animationPlayState: 'running'}}
                                viewport={{once: true, amount: 0.1}}
                                className="row slideUpDivSlowly"
                                style={{
                                    animationDuration: '1.2s',
                                    animationTimingFunction: 'ease-out',
                                    animationDelay: '-50ms'
                                }}
                    >
                        <div className="col-12 filter-content">
                            {renderMobileAdvisoryBoard()}
                        </div>
                    </motion.div>
                </div>
            </div>
        )
    };

    return (
        <div>
            {renderHeader()}
            {renderLeadershipUI()}
            {renderAdvisoryBoardUI()}
            <Footer {...props}/>
        </div>
    );
}

export default TeamView;

