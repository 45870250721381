import React, {useState, useRef} from 'react';
import "./bottomMenuOtion.css"
import {Link, NavLink, useLocation} from "react-router-dom";

import homeIcon from "../../../assets/img/homeNotSelected.png"
import dataIcon from "../../../assets/img/dataNotSelected.png"
import biowareIcon from "../../../assets/img/biowareNotSelected.png"
import moreOptions from "../../../assets/img/moreOptions.png"
import servicesIcon from "../../../assets/img/servicesNotSelected.png"

import homeSelected from "../../../assets/img/homeSelected.png"
import dataSelected from "../../../assets/img/dataSelected.png"
import servicesSelected from "../../../assets/img/servicesSelected.png"
import moreOptionsSelected from "../../../assets/img/moreOptionsSelected.png"

import dataCurationIcon from "../../../assets/img/bottomDataCurationIcon.png"
import researchInformaticsIcon from "../../../assets/img/bottomResearchInformaticsIcon.png"
import bioinformaticsIcon from "../../../assets/img/bottomBioinformaticsIcon.png"
import cheminformaticsIcon from "../../../assets/img/bottomCheminformaticsIcon.png"
import clinicalDataIcon from "../../../assets/img/bottomClinicalDataIcon.png"

import dataCurationSelected from "../../../assets/img/bottomDataCurationIconSelected.png"
import researchInfoSelected from "../../../assets/img/bottomResearchInfoIconSelected.png"
import bioinformaticsSelected from "../../../assets/img/bottomBioinformaticsIconSelected.png"
import cheminformaticsIconSelected from "../../../assets/img/bottomCheminformaticsIconSelected.png"
import clinicalDataSelected from "../../../assets/img/bottomClinicalDataIconSelected.png"

import bottomCorporateIcon from "../../../assets/img/bottomCorporateIcon.png"
import bottomCareersIcon from "../../../assets/img/bottomCareersIcon.png"
import bottomContactUsIcon from "../../../assets/img/bottomContactUsIcon.png"

import careersSelected from "../../../assets/img/bottomCareersIconSelected.png"
import contactUsSelected from "../../../assets/img/bottomContactUsIconSelected.png"


import Drawer from '@mui/material/Drawer';
import {isEmpty} from "../../../utils/validations";
import appTheme from "../../../assets/appTheme";

const bottomMainMenuOptions = [
    {
        id: 'about-navigation',
        title: 'Home',
        link: '/',
        icon: homeIcon,
        actionIcon: homeSelected,
    },
    {
        id: 'data-navigation',
        title: 'Data',
        link: '/data',
        icon: dataIcon,
        actionIcon: dataSelected
    },
    {
        id: 'bioware-navigation',
        title: 'Bioware',
        icon: biowareIcon,
        actionIcon: biowareIcon,
    },
];

const moreMenuOptions = [
    {
        id: 'careers-view',
        title: 'Careers',
        link: '/careers',
        icon: bottomCareersIcon,
        activeIcon: careersSelected
    },
    {
        id: 'select-contact-us',
        title: 'Contact Us',
        link: '/contactus',
        icon: bottomContactUsIcon,
        activeIcon: contactUsSelected
    }
];

const serviceMenuOptions = [
    {
        id: 'careers-view',
        title: 'Data Curation',
        link: '/services/data-curation',
        icon: dataCurationIcon,
        activeIcon: dataCurationSelected
    },
    {
        id: 'select-Research-Informatics',
        title: 'Research Informatics',
        link: '/services/research-info',
        icon: researchInformaticsIcon,
        activeIcon: researchInfoSelected
    },
    {
        id: 'select-Bioinformatics',
        title: 'Bioinformatics',
        link: '/services/bio-info',
        icon: bioinformaticsIcon,
        activeIcon: bioinformaticsSelected
    },
    {
        id: 'select-Cheminformatics',
        title: 'Cheminformatics',
        link: '/services/chem-info',
        icon: cheminformaticsIcon,
        activeIcon: cheminformaticsIconSelected
    },
    {
        id: 'select-Clinical-Data',
        title: 'Clinical Data',
        link: '/services/clinical',
        icon: clinicalDataIcon,
        activeIcon: clinicalDataSelected
    }
];

function BottomMenuOptionView(props) {

    const [openMoreDriver, setOpenMoreDriver] = useState(false);
    const [isCorporateOpen, setIsCorporateOpen] = useState(false);
    const [isServicesOpen, setIsServicesOpen] = useState(false);
    const ref = useRef();
    const {pathname, hash} = useLocation();


    const handleClick = () => {
        setIsServicesOpen(false);
        setIsCorporateOpen(true);
        setOpenMoreDriver(true);
    };

    const handleClickClose = () => {
        setIsServicesOpen(false);
        setOpenMoreDriver(false);
    };

    const handleClickService = () => {
        setIsServicesOpen(true);
        setIsCorporateOpen(false);
        setOpenMoreDriver(false);
    };

    const handleClickServiceClose = () => {
        setIsServicesOpen(false);
        setIsCorporateOpen(false);
    };

    const getActiveMoreTabClasses = () => {
        let pathsIncludedInMore = ['/careers', '/about', '/team', '/contactus', '/newsAndEvents'];
        if (pathsIncludedInMore.find((e) => e === pathname || pathname.startsWith(e) || e === hash)) {
            return 'bottom-menu bottom-menu-active-border-top';
        }
        return 'bottom-menu';
    };

    const getServicesMoreTabClasses = () => {
        let pathsIncludedInMore = ['/services/chem-info', '/services/data-curation', '/services/bio-info', '/services/research-info', '/services/clinical'];
        if (pathsIncludedInMore.find((e) => e === pathname)) {
            return 'bottom-menu bottom-menu-active-border-top';
        }
        return 'bottom-menu';
    };

    const isDataTabActive = (pathname) => {
        return (pathname === '/data/liceptor-database' || pathname === '/data/oncogen-database' || pathname === '/data/biomarker-database' || pathname === '/data/theme-database'
            || pathname === '/data/proximers-database' || pathname === '/data/adme-database' || pathname === '/data');
    };

    const isServicesTabActive = (pathname) => {
        let pathsIncludedInMore = ['/services/chem-info', '/services/data-curation', '/services/bio-info', '/services/research-info', '/services/clinical'];
        return (pathsIncludedInMore.find((e) => e === pathname))
    };

    const isMoreTabActive = (pathname) => {
        let pathsIncludedInMore = ['/careers', '/about', '/team', '/contactus', '/newsAndEvents'];
        return pathsIncludedInMore.find((e) => e === pathname)
    };

    const mainNavClassName = (bottomMenuOption) => {

        if (bottomMenuOption?.title === 'Data') {
            let isDataTabRendered = isDataTabActive(pathname);
            if (isDataTabRendered) {
                return {class: 'bottom-menu bottom-menu-active-border-top', icon: bottomMenuOption.actionIcon}
            }
            return {class: 'bottom-menu', icon: bottomMenuOption.icon}
        }
        if (bottomMenuOption.link === pathname) {
            return {class: 'bottom-menu bottom-menu-active-border-top', icon: bottomMenuOption.actionIcon};
        }
        if (bottomMenuOption?.childNavigations) {
            let obj = bottomMenuOption.childNavigations.find((e) => e.link === pathname);
            if (obj) {
                return {class: 'bottom-menu bottom-menu-active-border-top', icon: bottomMenuOption.actionIcon};
            }
        }
        return {class: 'bottom-menu', icon: bottomMenuOption.icon};
    };

    const toggleDrawer = (open) => (event) => {
        if (
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setIsCorporateOpen(!open ? false : isCorporateOpen);
        setOpenMoreDriver(open);
    };

    const handleRedirect = (path) => {
        setIsCorporateOpen(false);
        setOpenMoreDriver(false);
        setTimeout(() => {
            props.history.push(path);
        }, 500)


    };

    const renderBottomBar = () => {

        return (
            <div className="bottom-menu-container"
                 style={{gap: 20}}>
                {
                    bottomMainMenuOptions.map((bottomMenuOption) => {
                        if (isEmpty(bottomMenuOption.link)) {
                            return (
                                <div className='bottom-menu'>
                                    <div className="d-flex flex-column align-items-center"
                                         onClick={handleClickClose}
                                         style={{gap: 4}}>
                                        <img src={mainNavClassName(bottomMenuOption).icon}
                                             style={{
                                                 opacity: (bottomMenuOption.title === 'Bioware') ? '0.5' : 1
                                             }}
                                             alt={''}
                                             height={18}
                                             className=""/>
                                        <span className="bottom-menu-name"
                                              style={{
                                                  color: (bottomMenuOption.title === 'Bioware') ? 'var(--disable)' : 'auto',
                                              }}>{bottomMenuOption.title}
                                        </span>
                                    </div>
                                </div>
                            )
                        }
                        let selectedClasses = mainNavClassName(bottomMenuOption).class;
                        let isMenuActive = (selectedClasses !== 'bottom-menu');

                        return (
                            <NavLink to={bottomMenuOption.link}
                                     className={() => mainNavClassName(bottomMenuOption).class}>
                                <div className="d-flex flex-column align-items-center"
                                     onClick={handleClickClose}
                                     style={{gap: 4}}>
                                    <img src={mainNavClassName(bottomMenuOption).icon}
                                         alt=''
                                         height={18}
                                         className=""/>
                                    <span className="bottom-menu-name"
                                          style={{
                                              color: isMenuActive ? appTheme.appColor.greenBright : appTheme.appColor.primary,
                                              fontWeight: isMenuActive ? 'var(--font-weight-bold)' : 'var(--font-weight-normal)'
                                          }}>
                                   {bottomMenuOption.title}
                            </span>
                                </div>
                            </NavLink>
                        )
                    })
                }
                <div className={getServicesMoreTabClasses()}
                     style={{flex: 1}}
                     ref={ref}
                     onClick={handleClickService}>
                    <div className="d-flex flex-column align-items-center"
                         style={{gap: 4}}>
                        <img src={(isServicesTabActive(pathname)) ? servicesSelected : servicesIcon}
                             alt=''
                             height={16}
                             className=""/>
                        <span className="bottom-menu-name"
                              style={{
                                  color: (isServicesTabActive(pathname)) ? appTheme.appColor.greenBright : appTheme.appColor.primary,
                                  fontWeight: (isServicesTabActive(pathname)) ? 'var(--font-weight-bold)' : 'var(--font-weight-normal)'
                              }}>
                      Services
                    </span>
                    </div>
                </div>

                <div className={getActiveMoreTabClasses()}
                     style={{flex: 1}}
                     ref={ref}
                     onClick={handleClick}>
                    <div className="d-flex flex-column align-items-center" style={{gap: 4}}>
                        <img src={isMoreTabActive(pathname) ? moreOptionsSelected : moreOptions}
                             alt=''
                             height={16}
                             className=""/>
                        <span className="bottom-menu-name"
                              style={{
                                  color: isMoreTabActive(pathname) ? appTheme.appColor.greenBright : appTheme.appColor.primary,
                                  fontWeight: isMoreTabActive(pathname) ? 'var(--font-weight-bold)' : 'var(--font-weight-normal)'
                              }}>
                      More
                    </span>
                    </div>
                </div>
            </div>)

    };

    const renderServicesTabSubMenu = () => {
        return (
            <Drawer
                anchor="bottom"
                open={isServicesOpen}
                onClose={toggleDrawer(false)}
                sx={{
                    width: '100%',
                    maxWidth: 'unset',
                    bottom: 60,
                    '& .MuiModal-backdrop': {
                        bottom: 60
                    },
                    '& .MuiDrawer-paper': {
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                        bottom: 60,
                        boxShadow: 'none'
                    }
                }}>
                {setIsServicesOpen && <div className="container-top-border-radius"
                                           role="presentation">
                    <div className="more-container">

                        <div className="d-flex justify-content-between align-items-center mx-3"
                             onClick={toggleDrawer(false)}
                             onKeyDown={toggleDrawer(false)}>
                            <div/>
                            <div className="more-option-line"/>
                            <div className="d-flex justify-content-end">
                                <i className="fa-solid fa-xmark" style={{fontSize: "24px"}}
                                   onClick={handleClickServiceClose}
                                />
                            </div>
                        </div>

                        {
                            serviceMenuOptions.map((serviceMenuOption, index) => {
                                return <Link className="more-container-option mx-3 p-3"
                                             style={{
                                                 border: (index === 4) ? 'none' : 'auto'
                                             }}
                                             to={serviceMenuOption.link}
                                             onClick={handleClickServiceClose}>
                                    <span
                                        className={(pathname === serviceMenuOption.link) ? "more-option-menu-text-selected" : "more-option-menu-text"}>
                                        <div className='d-flex align-items-center'>
                                            <div style={{
                                                width : '19px'
                                            }}>
                                                <img
                                                    src={(pathname === serviceMenuOption.link) ? serviceMenuOption.activeIcon : serviceMenuOption.icon}
                                                    alt={''}
                                                    height={16}
                                                    className=""/>
                                            </div>
                                                 <div className='ps-3'
                                                      style={{
                                                          fontWeight : (pathname === serviceMenuOption.link) ? 'var(--font-weight-semibold)' : 'var(--font-weight-normal)'
                                                      }}>
                                                     {serviceMenuOption.title}
                                                 </div>
                                        </div>
                                    </span>
                                </Link>
                            })}

                    </div>

                </div>}

            </Drawer>
        )
    };

    const renderMoreTabSubMenu = () => {
        return (
            <Drawer
                anchor="bottom"
                open={openMoreDriver}
                onClose={toggleDrawer(false)}
                sx={{
                    width: '100%', maxWidth: 'unset',
                    bottom: 60,
                    '& .MuiModal-backdrop': {
                        bottom: 60
                    },
                    '& .MuiDrawer-paper': {
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                        bottom: 60,
                        boxShadow: 'none'
                    }
                }}>
                {openMoreDriver && <div className="container-top-border-radius"
                                        role="presentation"

                    // Adjust the height of the drawer
                >

                    <div className="more-container">

                        <div className="d-flex justify-content-between align-items-center mx-3"
                             onClick={toggleDrawer(false)}
                             onKeyDown={toggleDrawer(false)}>
                            <div/>
                            <div className="more-option-line"/>
                            <div className="d-flex justify-content-end">
                                <i className="fa-solid fa-xmark" style={{fontSize: "24px"}}
                                   onClick={handleClickClose}
                                />
                            </div>
                        </div>

                        <div className="more-container-option mx-3 p-3">
                        <span className="more-option-menu-text">
                            <div
                                className={'d-flex align-items-center p-0 justify-content-between' + (isCorporateOpen ? " pb-3" : "")}
                                onClick={() => setIsCorporateOpen(!isCorporateOpen)}>
                            <span className='more-option-menu-text'>

                                <div className='d-flex align-items-center'>
                                            <img src={bottomCorporateIcon}
                                                 alt={''}
                                                 height={16}
                                                 className=""/>
                                                 <div className='ps-3'>
                                                     Corporate
                                                 </div>
                                        </div>

                            </span>
                                {isCorporateOpen ?
                                    <i className="fa-solid fa-angle-up cursor"
                                       style={{fontSize: 'var(--font-size-sm)'}}/>
                                    :
                                    <i className="fa-solid fa-angle-down cursor"
                                       style={{fontSize: 'var(--font-size-sm)'}}/>
                                }

                        </div>
                            {isCorporateOpen &&
                            <div className='d-flex flex-column block-container-navbar-child border-top'
                                 style={{paddingLeft: 50}}>
                                <div>
                                    <div className='cursor'
                                         onClick={() => {
                                             handleRedirect('/about')
                                         }}>
                                        <Link
                                            className={pathname === "/about" ? 'navbar usecases-menu-selected' : 'navbar'}
                                            to={'/about'}>
                                            <div className='d-flex align-items-center'>
                                                <div className='bottom-submenu'/>
                                                <div className='ps-3'>
                                                    About
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <div>
                                    <div className='cursor'
                                         onClick={() => {
                                             handleRedirect('/team')
                                         }}>
                                        <Link
                                            className={pathname === "/team" ? 'navbar usecases-menu-selected' : 'navbar'}
                                            to={'/team'}>
                                            <div className='d-flex align-items-center'>
                                                <div className={'bottom-submenu'}/>
                                                <div className='ps-3'>
                                                    Team
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <div>
                                    <div className='cursor'
                                         onClick={() => {
                                             handleRedirect('/newsAndEvents')
                                         }}>
                                        <Link
                                            className={pathname === "/newsAndEvents" ? 'navbar usecases-menu-selected' : 'navbar'}
                                            style={{border: "none"}}
                                            to={'/newsAndEvents'}>
                                            <div className='d-flex align-items-center'>
                                                <div className={'bottom-submenu'}/>
                                                <div className='ps-3'>
                                                    News & Events
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            }
                        </span>
                        </div>

                        {
                            moreMenuOptions.map((moreMenuOption, index) => {
                                return <Link className="more-container-option mx-3 p-3"
                                             style={{
                                                 border: (index === 1) ? 'none' : 'auto'
                                             }}
                                             to={moreMenuOption.link}
                                             onClick={handleClickClose}>
                                    <span
                                        className={(pathname === moreMenuOption.link) ? "more-option-menu-text-selected" : "more-option-menu-text"}>
                                        <div className='d-flex align-items-center'>
                                            <img
                                                src={(pathname === moreMenuOption.link) ? moreMenuOption?.activeIcon : moreMenuOption.icon}
                                                alt={''}
                                                height={16}
                                                className=""/>
                                                 <div className='ps-3'
                                                 style={{
                                                     fontWeight : (pathname === moreMenuOption.link) ? 'var(--font-weight-semibold)' : 'var(--font-weight-normal)'
                                                 }}>
                                                     {moreMenuOption.title}
                                                 </div>
                                        </div>
                                    </span>
                                </Link>
                            })
                        }

                    </div>

                </div>}

            </Drawer>
        )
    };

    return (
        <div>
            {renderBottomBar()}
            {renderServicesTabSubMenu()}
            {renderMoreTabSubMenu()}
        </div>
    );
}

export default BottomMenuOptionView;

